
  <template>
  <div>
    <AppTable
      store-module="news_category"
      :table-fields="tableFields"
      :filters="filters"
      :actions="actions"
      :form-fields="formFields"
      :table-header="tableHeader"
    />
  </div>
</template>

<script>
import AppTable from "@/components/table/AppTable.vue";
import i18n from "@/libs/i18n/index";

export default {
  name: "NewsCategory",
  components: {
    AppTable,
  },
  data() {
    return {
      tableFields: [
        { key: "edit_delete", label: i18n.t("edit_delete") || "Edit/Delete" },
        { key: "name_ar", label: i18n.t("name_ar") || "name_ar" },
      ],
      filters: [{ name: "createdAt", componentType: "AppDatePicker" }],
      actions: [
        { label: "Edit", variant: "primary", event: "edit" },
        { label: "Delete", variant: "danger", event: "delete" },
      ],
      formFields: [
        {
          component: "AppInput",
          attrs: {
            vModel: "name_ar",
            type: "text",
            required: true,
            label: i18n.t("name_ar") || "Name in Arabic",
          },
        },
      ],
      tableHeader: {
        addNewBtn: true,
        multiKeySearch: true,
      },
    };
  },
};
</script>
